<template>
    <!-- <Layout> -->
    <div class="px-1 mt-1">
        <div class="card form" ref="cardForm">
            <div class="card-body">
                <div v-if="loading">
                    <LoadingTableForm
                        :cols="2"
                        :rows="6"
                        class="table-bordered bg-white" />
                </div>
                <b-form v-else @submit.prevent="submitForm">
                    <table class="table table-bordered table-wrap table-form">
                        <thead>
                            <tr class="bg-dark bg-gradient">
                                <th class="text-light text-start" colspan="6">
                                    {{ $t('create multi-member') }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th class="bg-light bg-soft">
                                    {{ $t('type') }}
                                </th>
                                <td>
                                    <div class="d-flex gap-2 mt-2">
                                        <b-form-radio
                                            :disabled="pl.type == referrer_type"
                                            name="type"
                                            value="offline"
                                            v-model="pl.type">
                                            {{ $t('offline') }}
                                        </b-form-radio>
                                        <b-form-radio
                                            :disabled="pl.type == referrer_type"
                                            name="type"
                                            value="online"
                                            v-model="pl.type">
                                            {{ $t('online') }}
                                        </b-form-radio>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th class="bg-light bg-soft">
                                    <i
                                        :class="`mdi ${
                                            referrer_type == 'online'
                                                ? 'mdi-web text-info'
                                                : referrer_type == 'offline'
                                                ? 'mdi-storefront text-success'
                                                : ''
                                        } fs-5`"></i>
                                    <i
                                        :class="`mdi ${
                                            referrer_role == 'agent'
                                                ? 'mdi-account-tie text-danger'
                                                : referrer_role == 'player'
                                                ? 'mdi-account-cowboy-hat text-primary'
                                                : ''
                                        } fs-5`"></i>
                                    {{ $t('referrer') }}
                                </th>
                                <td>
                                    <input
                                        type="text"
                                        class="form-control"
                                        :class="{
                                            'is-invalid':
                                                referrer_role == 'agent'
                                                    ? v$.pl.agent_id.$error
                                                    : v$.pl.player_id.$error,
                                            'is-valid':
                                                !viewMode &&
                                                (referrer_role == 'agent'
                                                    ? pl.agent_id &&
                                                      !v$.pl.agent_id.$error
                                                    : pl.player_id &&
                                                      !v$.pl.player_id.$error),
                                        }"
                                        :placeholder="`${$t(
                                            'enter referrer code or username'
                                        )}`"
                                        v-model="pl.referrer" />
                                    <div
                                        v-if="
                                            v$.pl.agent_id.validReferrer
                                                .$invalid
                                        "
                                        class="invalid-feedback">
                                        {{
                                            $t(
                                                v$.pl.agent_id.validReferrer
                                                    .$message
                                            )
                                        }}
                                    </div>
                                    <div
                                        v-if="
                                            v$.pl.player_id.validReferrer
                                                .$invalid
                                        "
                                        class="invalid-feedback">
                                        {{
                                            $t(
                                                v$.pl.player_id.validReferrer
                                                    .$message
                                            )
                                        }}
                                    </div>
                                    <!-- <multiselect
                                        :placeholder="$t(`select agent`)"
                                        v-model="pl.agent"
                                        :options="agent_options"
                                        label="name"
                                        :can-deselect="false">
                                        <template
                                            v-slot:singlelabel="{ value }">
                                            <div class="flex-fill ps-2">
                                                <i
                                                    :class="`mdi mdi-numeric-${value.value.agent.level}-box fs-5 level-${value.value.agent.level} me-1`"></i>
                                                <span>{{ value.label }}</span>
                                            </div>
                                        </template>
                                        <template v-slot:option="{ option }">
                                            <div>
                                                <i
                                                    :class="`mdi mdi-numeric-${option.value.agent.level}-box fs-5 level-${option.value.agent.level} me-1`"></i>
                                                <span>{{
                                                    option.value.username
                                                }}</span>
                                            </div>
                                        </template>
                                    </multiselect> -->
                                </td>
                            </tr>
                            <tr>
                                <th class="bg-light bg-soft">
                                    {{ $t('username') }}
                                </th>
                                <td>
                                    <input
                                        type="text"
                                        :placeholder="`${$t('enter username')}`"
                                        v-model="pl.username"
                                        class="form-control"
                                        :class="{
                                            'is-invalid':
                                                submitted &&
                                                v$.pl.username.$error,
                                        }" 
                                        />
                                    <div
                                        v-if="v$.pl.username.required.$invalid"
                                        class="invalid-feedback">
                                        {{
                                            $t(v$.pl.username.required.$message)
                                        }}
                                    </div>
                                    <div
                                        v-if="pl.username && v$.pl.username.alphabetOnly.$invalid"
                                        class="invalid-feedback">
                                        {{
                                            $t(
                                                v$.pl.username.alphabetOnly
                                                    .$message
                                            )
                                        }}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th class="bg-light bg-soft">
                                    {{ $t('number') }}
                                </th>
                                <td>
                                    <div class="d-flex gap-1 w-100">
                                        <div class="flex-fill">
                                            <VueNumberFormat
                                                class="form-control text-end rounded-end"
                                                :class="{
                                                    'is-invalid':
                                                        submitted &&
                                                        v$.pl.start.$error,
                                                } "
                                                v-model:value="pl.start"
                                                :options="{
                                                    precision: 0,
                                                    prefix: '',
                                                    suffix: '',
                                                    decimal: '.',
                                                    thousand: '',
                                                    acceptNegative: false,
                                                    isInteger: true,
                                                }"></VueNumberFormat>
                                            <div
                                                v-for="(item, index) in v$.pl
                                                    .start.$errors"
                                                :key="index"
                                                class="invalid-feedback">
                                                <span v-if="item.$message">
                                                    {{ $t(item.$message) }}
                                                </span>
                                            </div>
                                        </div>
                                        <div class="flex-fill">
                                            <VueNumberFormat
                                                class="form-control text-end rounded-end"
                                                :class="{
                                                    'is-invalid':
                                                        submitted &&
                                                        v$.pl.end.$error,
                                                }"
                                                v-model:value="pl.end"
                                                :options="{
                                                    precision: 0,
                                                    prefix: '',
                                                    suffix: '',
                                                    decimal: '.',
                                                    thousand: '',
                                                    acceptNegative: false,
                                                    isInteger: true,
                                                }"></VueNumberFormat>
                                            <div
                                                v-for="(item, index) in v$.pl
                                                    .end.$errors"
                                                :key="index"
                                                class="invalid-feedback">
                                                <span v-if="item.$message">
                                                    {{ $t(item.$message) }}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th class="bg-light bg-soft">
                                    {{ $t('password') }}
                                </th>
                                <td>
                                    <input
                                        type="password"
                                        :placeholder="`${$t('enter password')}`"
                                        v-model="pl.password"
                                        class="form-control"
                                        :class="{
                                            'is-invalid':
                                                submitted &&
                                                v$.pl.password.$error,
                                        }" />
                                    <div
                                        v-for="(item, index) in v$.pl.password
                                            .$errors"
                                        :key="index"
                                        class="invalid-feedback">
                                        <span v-if="item.$message">
                                            {{ $t(item.$message) }}
                                        </span>
                                    </div>
                                </td>
                            </tr>
                            <tr class="bg-warning bg-soft">
                                <th class="text-start"  colspan="2">
                                    {{ $t('casino') }}
                                </th>
                            </tr>
                            <tr>
                                <th class="bg-light bg-soft">
                                    <div
                                        class="d-flex justify-content-between">
                                        {{ $t('baccarat') }}
                                        <b-form-checkbox switch v-model="pl.player.commissions.casino.table.enabled">
                                        </b-form-checkbox>
                                    </div>
                                </th>
                                <td>
                                    <div class="form-group form-percent">
                                        <input  
                                            type="text" 
                                            class="form-control text-end" 
                                            onkeypress="if(!/[\d.]/.test(event.key) || (event.key === '.' && this.value.includes('.'))) event.preventDefault()"
                                            oninput="if(this.value.startsWith('.')) this.value = '0' + this.value; 
                                                    if(this.value.includes('.') && this.value.split('.')[1].length > 2) this.value = this.value.slice(0, this.value.indexOf('.') + 3)"
                                            :disabled="!pl.player.commissions.casino.table.enabled"
                                            :class="{'is-invalid':submitted &&v$.pl.player.commissions.casino.table.value.$error,}"
                                            v-model="pl.player.commissions.casino.table.value"
                                            >
                                        <div
                                            v-for="(item, index) in v$
                                                .pl.player.commissions
                                                .casino.table.value
                                                .$errors"
                                            :key="index"
                                            class="invalid-feedback">
                                            <span v-if="item.$message">
                                                {{ $t(item.$message) }}
                                            </span>
                                        </div>
                                    </div>
                                    <!-- <VueNumberFormat 
                                        :disabled="!pl.player.commissions.casino.table.enabled"
                                        class="form-control text-end rounded-end"
                                        :class="{'is-invalid':submitted && v$.pl.player.commissions.casino.table.value.$error}"
                                        v-model:value="pl.player.commissions.casino.table.value"
                                        :options="{
                                                        precision: '',
                                                        prefix: '',
                                                        suffix: '%',
                                                        decimal: '.',
                                                        thousand: ',',
                                                        acceptNegative: false,
                                                        isInteger: true,
                                                    }"></VueNumberFormat>
                                    <div
                                        v-for="(item, index) in v$
                                            .pl.player.commissions
                                            .casino.table.value
                                            .$errors"
                                        :key="index"
                                        class="invalid-feedback">
                                        <span v-if="item.$message">
                                            {{ $t(item.$message) }}
                                        </span>
                                    </div> -->
                                </td>
                            </tr>
                            <tr>
                                <th class="bg-light bg-soft">
                                    <div
                                        class="d-flex justify-content-between">
                                        {{ $t('slots') }}
                                        <b-form-checkbox switch  v-model="pl.player.commissions.casino.slots.enabled">
                                        </b-form-checkbox>
                                    </div>
                                </th>
                                <td>
                                    <div class="form-group form-percent">
                                        <input  
                                            type="text" 
                                            class="form-control text-end" 
                                            onkeypress="if(!/[\d.]/.test(event.key) || (event.key === '.' && this.value.includes('.'))) event.preventDefault()"
                                            oninput="if(this.value.startsWith('.')) this.value = '0' + this.value; 
                                                    if(this.value.includes('.') && this.value.split('.')[1].length > 2) this.value = this.value.slice(0, this.value.indexOf('.') + 3)"
                                            :disabled="!pl.player.commissions.casino.slots.enabled"
                                            :class="{'is-invalid':submitted &&v$.pl.player.commissions.casino.slots.value.$error,}"
                                            v-model="pl.player.commissions.casino.slots.value"
                                            >
                                        <div
                                            v-for="(item, index) in v$
                                                .pl.player.commissions
                                                .casino.slots.value
                                                .$errors"
                                            :key="index"
                                            class="invalid-feedback">
                                            <span v-if="item.$message">
                                                {{ $t(item.$message) }}
                                            </span>
                                        </div>
                                    </div>
                                    <!-- <VueNumberFormat 
                                        :disabled="!pl.player.commissions.casino.slots.enabled"
                                        class="form-control text-end rounded-end"
                                        :class="{'is-invalid':submitted && v$.pl.player.commissions.casino.slots.value.$error}"
                                        v-model:value="pl.player.commissions.casino.slots.value"
                                        :options="{
                                                        precision: '',
                                                        prefix: '',
                                                        suffix: '%',
                                                        decimal: '.',
                                                        thousand: ',',
                                                        acceptNegative: false,
                                                        isInteger: true,
                                                    }"></VueNumberFormat>
                                    <div
                                        v-for="(item, index) in v$
                                            .pl.player.commissions
                                            .casino.slots.value
                                            .$errors"
                                        :key="index"
                                        class="invalid-feedback">
                                        <span v-if="item.$message">
                                            {{ $t(item.$message) }}
                                        </span>
                                    </div> -->
                                </td>
                            </tr>
                            <tr>
                                <th class="bg-light bg-soft">
                                    <div
                                        class="d-flex justify-content-between">
                                        {{ $t('losing') }}
                                        <b-form-checkbox switch v-model="pl.player.commissions.casino.losing.enabled">
                                        </b-form-checkbox>
                                    </div>
                                </th>
                                <td>
                                    <div class="form-group form-percent">
                                        <input  
                                            type="text" 
                                            class="form-control text-end" 
                                            onkeypress="if(!/[\d.]/.test(event.key) || (event.key === '.' && this.value.includes('.'))) event.preventDefault()"
                                            oninput="if(this.value.startsWith('.')) this.value = '0' + this.value; 
                                                    if(this.value.includes('.') && this.value.split('.')[1].length > 2) this.value = this.value.slice(0, this.value.indexOf('.') + 3)"
                                            :disabled="!pl.player.commissions.casino.losing.enabled"
                                            :class="{'is-invalid':submitted &&v$.pl.player.commissions.casino.losing.value.$error,}"
                                            v-model="pl.player.commissions.casino.losing.value"
                                            >
                                        <div
                                            v-for="(item, index) in v$
                                                .pl.player.commissions
                                                .casino.losing.value
                                                .$errors"
                                            :key="index"
                                            class="invalid-feedback">
                                            <span v-if="item.$message">
                                                {{ $t(item.$message) }}
                                            </span>
                                        </div>
                                    </div>
                                    <!-- <VueNumberFormat 
                                        :disabled="!pl.player.commissions.casino.losing.enabled"
                                        class="form-control text-end rounded-end"
                                        :class="{'is-invalid':submitted && v$.pl.player.commissions.casino.losing.value.$error}"
                                        v-model:value="pl.player.commissions.casino.losing.value"
                                        :options="{
                                                        precision: '',
                                                        prefix: '',
                                                        suffix: '%',
                                                        decimal: '.',
                                                        thousand: ',',
                                                        acceptNegative: false,
                                                        isInteger: true,
                                                    }"></VueNumberFormat>
                                    <div
                                        v-for="(item, index) in v$
                                            .pl.player.commissions
                                            .casino.losing.value
                                            .$errors"
                                        :key="index"
                                        class="invalid-feedback">
                                        <span v-if="item.$message">
                                            {{ $t(item.$message) }}
                                        </span>
                                    </div> -->
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="text-center">
                        <button type="submit" class="btn btn-danger">
                            {{ $t('complete') }}
                        </button>
                    </div>
                </b-form>
            </div>
        </div>
    </div>
    <!-- </Layout> -->
</template>

<script>
import Layout from '../../layouts/main';
import PageHeader from '@/components/page-header';
import LoadingTableForm from '@/components/loading-table-form';

import Switches from 'vue-switches';
import { mapActions } from 'vuex';
import datetime from '../../../mixins/datetime';
import ui from '../../../mixins/ui';
import number from '../../../mixins/number';
import string from '../../../mixins/string';
import convert from '../../../mixins/convert';
import { required, minValue, maxValue , helpers } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import Multiselect from '@vueform/multiselect';
import Swal from 'sweetalert2';

const alphabetOnly = (value) => {
    const regex = /^[A-Za-z]+$/;
    return helpers.req(value) && regex.test(value)
};

export default {
    setup() {
        return { v$: useVuelidate() };
    },
    components: {
        Layout,
        PageHeader,
        Switches,
        Multiselect,
        LoadingTableForm,
    },
    mixins: [datetime, number, string, convert, ui],
    data() {
        return {
            title: null,
            submitted: false,
            loading: false,
            _id: this.$route.query.id,
            pl: {
                type: 'offline',
                referrer: null,
                agent_id: null,
                username: null,
                start: 0,
                end: 0,
                password: null,
                player: {
                    commissions: {
                        casino: {
                            table: {
                                value: 0,
                                enabled: false,
                            },
                            slots: {
                                value: 0,
                                enabled: false,
                            },
                            losing: {
                                value: 0,
                                enabled: false,
                            },
                        },
                    },
                }
            },
            referrer_role: null,
            referrer_type: 'offline',
            agent_options: [],
        };
    },
    validations() {
        return {
            pl: {
                referrer: {
                    // required: helpers.withMessage('required', required),
                },
                agent_id: {
                    validReferrer: helpers.withMessage(
                        'invalid referrer',
                        function (value) {
                            return !this.viewMode &&
                                this.referrer_code &&
                                this.referrer_role == 'agent'
                                ? this.pl.agent_id != null
                                : true;
                        }
                    ),
                },
                player_id: {
                    validReferrer: helpers.withMessage(
                        'invalid referrer',
                        function (value) {
                            return !this.viewMode &&
                                this.referrer_code &&
                                this.referrer_role == 'player'
                                ? this.pl.player_id != null
                                : true;
                        }
                    ),
                },
                username: {
                    required: helpers.withMessage('required', required),
                    alphabetOnly: helpers.withMessage(
                        'must consist of alphabet characters only',
                        alphabetOnly
                    ),
                },
                start: {
                    required: helpers.withMessage('required', required),
                    maxValue: helpers.withMessage(
                        'must be less than end number',
                        function (value) {
                            return value < this.pl.end;
                        }
                    ),
                },
                end: {
                    required: helpers.withMessage('required', required),
                    minValue: helpers.withMessage(
                        'must be greater than start number',
                        function (value) {
                            return value > this.pl.start;
                        }
                    ),
                },
                password: {
                    required: helpers.withMessage('required', required),
                },
                player: {
                    commissions: {
                        casino: {
                            table: {
                                value: {
                                    maxValueValue: maxValue(100),
                                },
                            },
                            slots: {
                                value: {
                                    maxValueValue: maxValue(100),
                                },
                            },
                            losing: {
                                value: {
                                    maxValueValue: maxValue(100),
                                },
                            },
                        },
                    },
                },
            },
        };
    },
    watch: {
        'pl.referrer'() {
            this.getReferrer();
        },
    },
    methods: {
        ...mapActions('player', {
            playerBatchStore: 'batchStore',
        }),
        ...mapActions('agent', {
            agentGetFilterList: 'getFilterList',
        }),
        ...mapActions('referrer', {
            referrerView: 'view',
        }),
        async submitForm() {
            if (!this.isAuthorized()) return;
            this.submitted = true;
            this.v$.pl.$touch();
            if (this.v$.pl.$invalid) {
                return;
            } else {
                var fd = new FormData();
                if (this.pl.agent_id) fd.append('agent_id', this.pl.agent_id);
                if (this.pl.player_id)
                    fd.append('player_id', this.pl.player_id);
                // fd.append('type', this.pl.type);
                fd.append('type', 'offline');
                fd.append('username', this.pl.username);
                fd.append('start', this.pl.start);
                fd.append('end', this.pl.end);
                fd.append('password', this.pl.password);
                fd.append('player[commissions][casino][table][enabled]', this.pl.player.commissions.casino.table.enabled ?'1':'0');
                fd.append('player[commissions][casino][table][value]', this.pl.player.commissions.casino.table.value);
                fd.append('player[commissions][casino][slots][enabled]', this.pl.player.commissions.casino.slots.enabled ?'1':'0');
                fd.append('player[commissions][casino][slots][value]', this.pl.player.commissions.casino.slots.value);
                fd.append('player[commissions][casino][losing][enabled]', this.pl.player.commissions.casino.losing.enabled ?'1':'0');
                fd.append('player[commissions][casino][losing][value]', this.pl.player.commissions.casino.losing.value);

                const res = await this.playerBatchStore(fd);

                if (res) {
                    if (res.success) {
                        window.opener.postMessage('reload-players');
                        this.reset();
                    }
                    Swal.fire({
                        title: this.$t(res.data.message),
                        icon: res.data.status,
                        timer: 2000,
                        confirmButtonText: this.$t('ok'),
                    });
                }
            }
        },
        async initAgents() {
            const data = await this.agentGetFilterList({});
            data.forEach((agent) => {
                this.agent_options.push({
                    label: agent.username,
                    value: agent,
                });
            });
        },
        async getReferrer() {
            this.v$.pl.agent_id.$touch();
            this.pl.agent_id = null;
            this.v$.pl.player_id.$touch();
            this.pl.player_id = null;
            this.referrer_role = null;

            // this.referrer_type = null;
            if (this.pl.referrer == null || this.pl.referrer == '') return;

            const referrer = await this.referrerView(this.pl.referrer);
            if (referrer) {
                // this.pl.type = referrer.type;
                this.pl[`${referrer.role}_id`] = referrer._id;
                this.referrer_role = referrer.role;
                // this.referrer_type = referrer.type;
            }
        },
        reset() {
            this.submitted = false;
            this.pl.referrer = null;
            this.pl.type = null;
            this.pl.agent_id = null;
            this.pl.player_id = null;
            this.pl.username = null;
            this.pl.start = 0;
            this.pl.end = 0;
            this.pl.password = null;
            this.pl.player.commissions.casino.table.value = 0;
            this.pl.player.commissions.casino.table.enabled = false;
            this.pl.player.commissions.casino.slots.value = 0;
            this.pl.player.commissions.casino.slots.enabled = false;
            this.pl.player.commissions.casino.losing.value = 0;
            this.pl.player.commissions.casino.losing.enabled = false;
        },
    },
    mounted() {
        this.initAgents();
    },
};
</script>
